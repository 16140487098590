import { map } from 'rxjs/operators';
import { Informationen } from './../_models/informationen';
import { User } from './../_models/user';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InformationenService {

  private hostUrl = environment.hostUrl;

  constructor(
    private http: HttpClient,
  ) { }

  public getHeader(accesstoken: string) {
    const headers = ({
      'Authorization': `Bearer ${accesstoken}`
    });
    return headers
  }


  public getInformations(infoObject: object): Observable<Informationen[]> {
    return this.http.post<Informationen[]>(`${this.hostUrl}/informationen`,infoObject )
      .pipe(map((informationen: Informationen[]) => {
        console.log(informationen);
        return informationen
      }));
  }
  public getFiveInformations(infoObject: object): Observable<Informationen[]> {
    return this.http.post<Informationen[]>(`${this.hostUrl}/informationen/five`,infoObject )
      .pipe(map((informationen: Informationen[]) => {
        console.log(informationen);
        return informationen
      }));
  }


  public addInformation(infoObject: object): Observable<Informationen> {
    return this.http.post<Informationen>(`${this.hostUrl}/informationen/add`,infoObject )
      .pipe(map((informationen: Informationen) => {
        console.log(informationen);
        return informationen
      }));
  }
}
