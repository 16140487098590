<!-- This example requires Tailwind CSS v2.0+ -->
<div class="relative bg-white overflow-hidden">
    <div class="max-w-7xl mx-auto">
      
        <app-navbar></app-navbar>
        

    <div class="sm:mx-auto sm:w-full sm:max-w-md my-">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Melden Sie sich am Lehrerportal an
        </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <app-login-form></app-login-form>
        </div>
    </div>


    </div>
  </div>



  
  <app-footer></app-footer>