<div class="h-screen flex overflow-hidden bg-gray-100">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
          <div class="py-6">
              
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <!-- This example requires Tailwind CSS v2.0+ -->
  
              <div class="md:flex md:items-center md:justify-between mr-8">
                <div class="flex-1 min-w-0 ">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    Wichtige Informationen
                </h2>
                </div>
                <div class="mt-4 flex md:mt-0 md:ml-4">
                <button *ngIf="user.isAdmin" (click)="emitOpen()" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Information hinzufügen
                </button>
                </div>
                
            </div>
            <br>
            <hr>


            <!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul class="divide-y divide-gray-200">

        <app-informationen-list-element [information]="information" *ngFor="let information of informationen" ></app-informationen-list-element>
  

    </ul>
  </div>
  
            
            
            <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div *ngIf="filteredTickets.length == 0">
                <h1>Es sind keine Tickets in dieser Kategorie vorhanden...</h1>
            </div>
            <div   class="bg-white shadow overflow-hidden sm:rounded-md">
            <ul class="divide-y divide-gray-200">
            
            <app-ticket-list-element   [ticket]="ticket" *ngFor="let ticket of filteredTickets"></app-ticket-list-element>
            </ul>
            </div>
            </div> -->
            
            <!-- <app-add-ticket (ping)="addUser($event)" [openEvent]="eventsSubject.asObservable()"></app-add-ticket>
            
            <app-loading-screen *ngIf="loading"></app-loading-screen> -->

              <!-- /End replace -->
            </div>
          </div>
        </main>
      </div>
  </div>
  <app-add-informationen (ping)="addInformationen($event)"  [openEvent]="eventsSubject.asObservable()"></app-add-informationen> 