import { TokenInterceptor } from './_services/token-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './_pages/index/index.component';
import { LoginComponent } from './_pages/login/login.component';
import { DashboardComponent } from './_pages/dashboard/dashboard.component';
import { TicketComponent } from './_pages/ticketmodule/ticket/ticket.component';
import { LoginFormComponent } from './_components/login-form/login-form.component';
import { TicketListComponent } from './_components/tickets/ticket-list/ticket-list/ticket-list.component';
import { TicketListElementComponent } from './_components/tickets/ticket-list/ticket-list-element/ticket-list-element.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarComponent } from './_components/navbar/navbar/navbar.component';
import { DashboardNavbarComponent } from './_components/navbar/dashboard-navbar/dashboard-navbar.component';
import { MeineTicketsComponent } from './_pages/ticketmodule/meine-tickets/meine-tickets.component';
import { ZuBearbeitendeTicketsComponent } from './_pages/ticketmodule/zu-bearbeitende-tickets/zu-bearbeitende-tickets.component';
import { AddTicketComponent } from './_components/tickets/add-ticket/add-ticket.component';
import { UpdateTicketComponent } from './_components/tickets/update-ticket/update-ticket.component';
import { BenutzerComponent } from './_pages/benutzer/benutzer.component';
import { BenutzerListComponent } from './_components/benutzer/benutzer-list/benutzer-list/benutzer-list.component';
import { BenutzerListElementComponent } from './_components/benutzer/benutzer-list/benutzer-list-element/benutzer-list-element.component';
import { LoadingScreenComponent } from './_components/loading-screen/loading-screen.component';
import { FooterComponent } from './_components/footer/footer.component';
import { TicketsComponent } from './_pages/ticketmodule/tickets/tickets.component';
import { SchortcutsListComponent } from './_components/schortcuts/schortcuts-list/schortcuts-list.component';
import { SchortcutsListElementComponent } from './_components/schortcuts/schortcuts-list-element/schortcuts-list-element.component';
import { AddShortcutComponent } from './_components/schortcuts/add-shortcut/add-shortcut.component';
import { ProzessmanagementComponent } from './_pages/prozessmanagement/prozessmanagement.component';
import { KalenderComponent } from './_pages/kalender/kalender.component';
import { CalendarComponent } from './_components/informationen/calendar/calendar.component';
import { CalendarListComponent } from './_components/informationen/calendar-list/calendar-list.component';
import { CalendarListElementComponent } from './_components/informationen/calendar-list-element/calendar-list-element.component';
import { InformationenComponent } from './_pages/informationen/informationen.component';
import { InformationenListElementComponent } from './_components/informationen/informationen-list-element/informationen-list-element.component';
import { AddInformationenComponent } from './_components/informationen/add-informationen/add-informationen.component';
import { WichtigeLinksComponent } from './_components/wichtige-links/wichtige-links.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    LoginComponent,
    DashboardComponent,
    TicketComponent,
    LoginFormComponent,
    TicketListComponent,
    TicketListElementComponent,
    NavbarComponent,
    DashboardNavbarComponent,
    MeineTicketsComponent,
    ZuBearbeitendeTicketsComponent,
    AddTicketComponent,
    UpdateTicketComponent,
    BenutzerComponent,
    BenutzerListComponent,
    BenutzerListElementComponent,
    LoadingScreenComponent,
    FooterComponent,
    TicketsComponent,
    SchortcutsListComponent,
    SchortcutsListElementComponent,
    AddShortcutComponent,
    ProzessmanagementComponent,
    KalenderComponent,
    CalendarComponent,
    CalendarListComponent,
    CalendarListElementComponent,
    InformationenComponent,
    InformationenListElementComponent,
    AddInformationenComponent,
    WichtigeLinksComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule, 
    FormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
