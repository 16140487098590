import { first } from 'rxjs/operators';
import { InformationenService } from './../../../_services/informationen.service';
import { User } from './../../../_models/user';
import { LocalStorageService } from './../../../_services/local-storage.service';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-informationen',
  templateUrl: './add-informationen.component.html',
  styleUrls: ['./add-informationen.component.scss']
})
export class AddInformationenComponent implements OnInit {

  public isOpen: Boolean = false;
  user: User;
  public date: Date = new Date();

  public message = {
    status: false,
    text: ''
  }

  loading: Boolean =  false;
  submitted:Boolean =  false;
  addInformationenForm: FormGroup;

  private eventSubscription: Subscription;
  @Input() openEvent: Observable<void>;
  @Output() ping: EventEmitter<any> = new EventEmitter<any>();

 
  constructor(
    private formbuilder: FormBuilder,
    private localstorageService: LocalStorageService,
    private informationsService: InformationenService

  ) {
    this.user = this.localstorageService.getCurrentUser();
   }

  ngOnInit(): void {
    this.eventSubscription = this.openEvent.subscribe(() => {
      this.isOpen = !this.isOpen;
    });

    //Form
    this.addInformationenForm = this.formbuilder.group({
      titel: ['', Validators.required],
      text: [''],
      ablaufdatum: ['', Validators.required]
    })

  }


  public onSubmit(): void {
    console.log('test');
    this.submitted = true;
    this.message.status = false;

    if (this.addInformationenForm.invalid) {
      return
    }
    console.log('test2');
    this.loading = true;

    var information = {
      titel: this.addInformationenForm.controls.titel.value,
      text: this.addInformationenForm.controls.text.value.replaceAll('\n', '<br>'),
      ersteller: this.user.id,
      ablaufdatum: new Date(this.addInformationenForm.controls.ablaufdatum.value)
    }

    console.log(information);

    this.informationsService.addInformation(information)
      .pipe(first())
      .subscribe(
        data => {
          this.ping.emit(data);
          this.isOpen = !this.isOpen;
          this.loading = false;
          this.addInformationenForm.reset();

        },
        error => {
          this.message = {
            status: true,
            text: error.message
          }
          this.loading = false;
        }
      )

  }
}
