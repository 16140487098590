<!-- This example requires Tailwind CSS v2.0+ -->
<footer class="bg-white fixed bottom-0 w-full ">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">

        <a href="#" class="text-gray-400 hover:text-gray-500">
            Datenschutzerklärung
        </a>
  
        <a href="#" class="text-gray-400 hover:text-gray-500">
            Impressum
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <a class="text-center text-base text-gray-400">
          &copy; Made with ☕ &nbsp; in Baden-Württemberg, Germany
        </a>
      </div>
    </div>
  </footer>
  