import { User } from './../../_models/user';
import { LocalStorageService } from './../../_services/local-storage.service';
import { interval, Subject } from 'rxjs';
import { InformationenService } from './../../_services/informationen.service';
import { Informationen } from 'src/app/_models/informationen';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informationen',
  templateUrl: './informationen.component.html',
  styleUrls: ['./informationen.component.scss']
})
export class InformationenComponent implements OnInit {
  eventsSubject: Subject<void> =  new Subject<void>();

  informationen: Informationen[];

  user: User;

  constructor(
    private informationsService: InformationenService,
    private localstorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.user = this.localstorageService.getCurrentUser();
    this.loadInformation();

    interval(300000).subscribe(() => {
      this.loadInformation();
    });
  }

  public loadInformation(): void {
    this.informationen = [];
    var date: Date = new Date();
    date = new Date(date.setDate(date.getDate() -1));
    
    this.informationsService.getInformations({expirationDate: date }).subscribe((informationen: Informationen[]) => {
      informationen.forEach(element => {
        this.informationen.push(element);
      })
    },
    error => {
      console.log('error');
      console.log(error.message);
    });
  }

  public addInformationen(event: Informationen): void {
    console.log(event);
    this.informationen.unshift(event);
  }

  public emitOpen() {
    this.eventsSubject.next();
  } 
}
