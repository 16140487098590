import { Informationen } from './../../_models/informationen';
import { InformationenService } from './../../_services/informationen.service';

import { Component, OnInit } from '@angular/core';
import { Subject, interval } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  titel: string = "Ticketsystem";

  informationen: Informationen[];
  
  constructor(
    private informationsService: InformationenService
  ) { }

  ngOnInit(): void {
    this.loadInformation();

    interval(300000).subscribe(() => {
      this.loadInformation();
    });
  }
 

  public loadInformation(): void {
    this.informationen = [];
    var date: Date = new Date();
    date = new Date(date.setDate(date.getDate() -1));
    
    this.informationsService.getFiveInformations({expirationDate: date }).subscribe((informationen: Informationen[]) => {
      informationen.forEach(element => {
        this.informationen.push(element);
      })
    },
    error => {
      console.log('error');
      console.log(error.message);
    });
  }

}
