import { InformationenService } from './../../../_services/informationen.service';
import { Informationen } from 'src/app/_models/informationen';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-informationen-list-element',
  templateUrl: './informationen-list-element.component.html',
  styleUrls: ['./informationen-list-element.component.scss']
})
export class InformationenListElementComponent implements OnInit {

  

  @Input() information!: Informationen; 

  public isExpanded: Boolean = false;

  constructor(
    
  ) { }

  ngOnInit(): void {
    console.log(this.information);
    
  }

 

}
