<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
    <div class="md:flex md:items-center md:justify-between">
        <div class="flex-1 min-w-0 mb-4">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Wichtige Direktlinks
        </h2>
      </div>
    </div>

      <div>
        <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <a href=" https://www.gs-bk.de/" target="_blank">
                <li class="col-span-1 flex shadow-sm rounded-md">
                    <div class="flex-shrink-0 flex items-center justify-center w-16 bg-red-700 text-white text-sm font-medium rounded-l-md">
                        WWW
                      </div>
                      <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md ">
                        <div class="flex-1 px-2 py-2 text-sm mr-10">
                          <p class="text-gray-900 font-medium hover:text-indigo-600">Homepage <br> www.gs-bk.de</p>
                        </div>
                      </div>
                </li> 
            </a>
            <a href="https://mail.gs-bk.de/" target="_blank">
                <li class="col-span-1 flex shadow-sm rounded-md">
                    <div class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-600 text-white text-sm font-medium rounded-l-md">
                        Mail
                      </div>
                      <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md ">
                        <div class="flex-1 px-2 py-2 text-sm mr-10">
                          <p class="text-gray-900 font-medium hover:text-indigo-600">Webmail <br> &nbsp; </p>
                        </div>
                      </div>
                </li> 
            </a>
            <a href="https://sql.gsbk.de/moodle" target="_blank">
                <li class="col-span-1 flex shadow-sm rounded-md">
                    <div class="flex-shrink-0 flex items-center justify-center w-16 bg-purple-700 text-white text-sm font-medium rounded-l-md">
                        Moodle
                      </div>
                      <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md ">
                        <div class="flex-1 px-2 py-2 text-sm mr-10">
                          <p class="text-gray-900 font-medium hover:text-indigo-600">Moodle <br> &nbsp; </p>
                        </div>
                      </div>
                </li> 
            </a>
            <a href="https://cloud.gs-bk.de/login" target="_blank">
                <li class="col-span-1 flex shadow-sm rounded-md">
                    <div class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-500 text-white text-sm font-medium rounded-l-md">
                        Cloud
                      </div>
                      <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md ">
                        <div class="flex-1 px-2 py-2 text-sm mr-10">
                          <p class="text-gray-900 font-medium hover:text-indigo-600">Nextcloud <br> &nbsp; </p>
                        </div>
                      </div>
                </li> 
            </a>
            <a href="https://borys.webuntis.com/WebUntis/?school=GWS+Backnang#/basic/login" target="_blank">
                <li class="col-span-1 flex shadow-sm rounded-md">
                    <div class="flex-shrink-0 flex items-center justify-center w-16 bg-yellow-600 text-white text-sm font-medium rounded-l-md">
                        Untis
                      </div>
                      <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md ">
                        <div class="flex-1 px-2 py-2 text-sm mr-10">
                          <p class="text-gray-900 font-medium hover:text-indigo-600">WebUntis <br> &nbsp; </p>
                        </div>
                      </div>
                </li> 
            </a>


        </ul>
      </div>




   
</div>






