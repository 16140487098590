import { InformationenComponent } from './_pages/informationen/informationen.component';
import { KalenderComponent } from './_pages/kalender/kalender.component';
import { ProzessmanagementComponent } from './_pages/prozessmanagement/prozessmanagement.component';
import { TicketsComponent } from './_pages/ticketmodule/tickets/tickets.component';
import { BenutzerComponent } from './_pages/benutzer/benutzer.component';
import { ZuBearbeitendeTicketsComponent } from './_pages/ticketmodule/zu-bearbeitende-tickets/zu-bearbeitende-tickets.component';
import { MeineTicketsComponent } from './_pages/ticketmodule/meine-tickets/meine-tickets.component';
import { AuthGuard } from './_helpers/auth.guard';
import { TicketComponent } from './_pages/ticketmodule/ticket/ticket.component';
import { DashboardComponent } from './_pages/dashboard/dashboard.component';
import { LoginComponent } from './_pages/login/login.component';
import { IndexComponent } from './_pages/index/index.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', component: IndexComponent},
  { path: 'login', component: LoginComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'meine-tickets/:id', component: MeineTicketsComponent, canActivate: [AuthGuard] },
  { path: 'zu-bearbeitende-tickets/:id', component: ZuBearbeitendeTicketsComponent, canActivate: [AuthGuard] },
  { path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard]},
  { path: 'ticket/:id', component: TicketComponent, canActivate: [AuthGuard]},
  { path: 'benutzer', component: BenutzerComponent, canActivate: [AuthGuard]},
  { path: 'prozessmanagement', component: ProzessmanagementComponent, canActivate: [AuthGuard]},
  { path: 'kalender', component: KalenderComponent, canActivate: [AuthGuard]},
  { path: 'wichtige-informationen', component: InformationenComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
