import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { Informationen } from 'src/app/_models/informationen';
import { InformationenService } from 'src/app/_services/informationen.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  informationen: Informationen[];

  constructor(
    private informationsService: InformationenService
  ) { }

  ngOnInit(): void {
   
  }


}
